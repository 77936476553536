import React from "react";
import PropTypes from "prop-types";
import { DlSelect } from "../../components/input";
import groupService from "../../services/groupService";
import levelService from "../../services/levelService";
import { getUid } from "../../utils/helpers/title";
import { isEmpty, toLower } from "lodash";
import { Button, List } from "antd";

export function SchoolTeacherLevels({
  formik = {},
  setLoading,
  loading = false,
  selecteds = [],
  setLevelSelection,
}) {
  const [cycles, setCycles] = React.useState([]);
  const [sections, setSections] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const [levels, setLevels] = React.useState([]);

  function setLoader(value) {
    if (typeof setLoader === "function") {
      setLoading(value);
    }
  }

  async function getCycles() {
    setLoader(true);
    await groupService
      .getByKey(`type=cycle&isVisible=true`)
      .then((response) => {
        const data = groupService.getData(response);
        const items = [];
        data.forEach((p) => {
          items.push({ value: getUid(p["@id"]), title: p.name });
        });
        items.unshift({ value: null, title: "Sélectionner un cycle" });
        setCycles(items);
      })
      .catch((reason) => {
        console.log(reason);
      });
    setLoader(false);
  }

  async function getElements(parent, type) {
    let items = [];
    setLoader(true);
    await groupService
      .getByKey(`type=${type}&parent=${parent}`)
      .then((response) => {
        const data = response.data["hydra:member"];
        items = data;
      })
      .catch((reason) => {
        console.log(reason);
      });
    setLoader(false);
    return items;
  }

  function handleChangeCycle() {
    setSections([{ value: null, title: "Sélectionner une section" }]);
    setOptions([{ value: null, title: "Sélectionner une option" }]);
    setLevels([]);
    formik.setValues({
      ...formik.values,
      section: null,
      option: null,
      classe: null,
    });
    const id = formik.values.cycle;
    handleLoadGroup(id);
  }

  function handleChangeSection() {
    let id = formik.values.section;
    formik.setValues({ ...formik.values, option: null, classe: null });
    if (!isEmpty(id)) {
      setOptions([{ value: null, title: "Sélectionner une option" }]);
      setLevels([]);
      handleLoadGroup(id);
    } else {
      handleChangeCycle();
    }
  }

  async function getLevels(group) {
    setLoader(true);
    await levelService
      .getByKey(`groups=${getUid(group)}&order[promotion]=asc`)
      .then((response) => {
        const data = levelService.getData(response);
        setLevels(data);
      })
      .catch((reason) => console.log("REASON", reason));
    setLoader(false);
  }

  async function handleLoadGroup(id) {
    if (!isEmpty(id)) {
      setLoader(true);
      let items = await getElements(id, "section");
      await getLevels(id);
      if (items.length > 0) {
        const elements = [];
        items.forEach((p) => {
          elements.push({ value: getUid(p["@id"]), title: p.name });
        });
        elements.unshift({ value: null, title: "Sélectionner une section" });
        setSections(elements);
        return;
      }
      items = await getElements(id, "option_class");
      if (items.length > 0) {
        const elements = [];
        items.forEach((p) => {
          elements.push({ value: getUid(p["@id"]), title: p.name });
        });
        elements.unshift({ value: null, title: "Sélectionner une option" });
        setOptions(elements);
        return;
      }
      setLoader(false);
    }
  }

  React.useEffect(() => {
    getCycles();
  }, []);
  React.useEffect(() => {
    handleChangeCycle();
  }, [formik.values.cycle]);

  React.useEffect(() => {
    handleChangeSection();
  }, [formik.values.section]);
  React.useEffect(() => {
    let id = formik.values.option;
    formik.setValues({ ...formik.values, classe: null });
    if (!isEmpty(id)) {
      setLevels([]);
      handleLoadGroup(id);
    } else {
      handleChangeSection();
    }
  }, [formik.values.option]);

  function handleSelection(item) {
    if (typeof setLevelSelection === "function") {
      const uid = item.uid;
      let elements = selecteds;
      const cycle = elements.find(
        (p) =>
          toLower(p?.groups?.type) === "cycle" &&
          (toLower(p?.groups?.name).includes("maternel") ||
            toLower(p?.groups?.name).includes("primaire")),
      );
      const isPrimaryOrMaternel =
        toLower(item?.groups?.type) === "cycle" &&
        (toLower(item?.groups?.name).includes("maternel") ||
          toLower(item?.groups?.name).includes("primaire"));
      const x = elements.findIndex((p) => p.uid === uid);
      if (x === -1) {
        if (!isEmpty(cycle) && isPrimaryOrMaternel) {
          return;
        }
        elements = [...elements, item];
        setLevelSelection(elements);
      } else {
        elements = elements.filter((p) => p.uid !== uid);
      }
      setLevelSelection(elements);
    }
  }

  const renderItem = (item, index) => {
    return (
      <List.Item key={`level_${index}`}>
        <Button
          onClick={() => handleSelection(item)}
          style={{ width: "100%" }}
          type={
            selecteds.findIndex((p) => p.uid === item.uid) !== -1
              ? "primary"
              : "default"
          }
        >
          {item.title}
        </Button>
      </List.Item>
    );
  };

  return (
    <React.Fragment>
      <div style={{ margin: "0 20px" }}>
        <DlSelect label="" formik={formik} name="cycle" list={cycles} />
        {formik.values.cycle != null && sections.length > 1 && (
          <div className="yc-input-div">
            <DlSelect label="" formik={formik} name="section" list={sections} />
          </div>
        )}
        {formik.values.section != null && options.length > 1 && (
          <div className="yc-input-div">
            <DlSelect label="" formik={formik} name="option" list={options} />
          </div>
        )}
        <List dataSource={levels} loading={loading} renderItem={renderItem} />
      </div>
    </React.Fragment>
  );
}

SchoolTeacherLevels.propTypes = {
  formik: PropTypes.any,
  setLoading: PropTypes.func,
  loading: PropTypes.bool,
  selecteds: PropTypes.array,
  setLevelSelection: PropTypes.any,
};
